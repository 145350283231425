<template>

  <div id="Produtos" v-if="dataReady">

	<div class="container">

		<div class="container text-center mb40">
			<img src="@/images/textos/os-nossos-produtos.svg" style="max-width: 100%;" alt="Pontos de Venda">
		</div>

		<div class="row mb80 d-flex align-items-center">

			<div class="produto col-12 col-md-4 text-center">
				<h3>{{ pageinfo.titulo1 }}</h3>
				<p>{{ pageinfo.subtitulo1 }}</p>
				<hr>
				<small>{{ pageinfo.texto1 }}</small>
			</div>

			<div class="col-12 col-md-8">
				<img :src="pageBaseUrl+'/'+pageinfo.imagem1.path" :alt="pageinfo.titulo1" style="width:100%">
			</div>

		</div>

		<div class="row text-center mb60">
			<div class="col-12">
				<img src="@/images/textos/batatas-fritas-receita-caseira.svg" style="max-width:100%;" alt="Batatas Fritas - Receita Caseira">
			</div>
		</div>

		<div class="row mb80 d-flex align-items-center">

			<div class="col-12 col-md-8">
				<img :src="pageBaseUrl+'/'+pageinfo.imagem2.path" :alt="pageinfo.titulo2" style="width:100%">
			</div>

			<div class="produto col-12 col-md-4 text-center">
				<h3>{{ pageinfo.titulo2 }}</h3>
				<p>{{ pageinfo.subtitulo2 }}</p>
				<hr>
				<small>{{ pageinfo.texto2 }}</small>
			</div>

		</div>

	</div>

	<MainFooter />

  </div>



</template>

<script>
/* IMPORT SCRIPTS */
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";

export default {
	name: "Produtos",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageBaseUrl: globals.BASE_URL,
			pageinfo: null
		};
	},
	beforeCreate: function() {
        document.body.className = 'pag-produtos';
    },
	mounted () {
		axios
			.get(globals.COCKPIT_SERVER+"singletons/get/produtos"+globals.COCKPIT_TOKEN)
			.then(response => {
				this.pageinfo = response.data
				this.dataReady = true
				this.changeMetaData()
			})
		;
	},
	metaInfo() {
		return {
			title: 'Produtos'
		}
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = this.$metaInfo.title;
			this.$parent.metaDescription = 'Experimente as nossas batatas fritas nas variedades Ondulada e Palha!';
		}
	}
};
</script>